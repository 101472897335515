<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="room-modal">
      <v-card-title>
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <IconPictoAarrowsLeft />
        </v-btn>
        <span class="room-modal__title"
          >{{ mode === "new" ? "Ajouter" : "Éditer" }} une pièce</span
        >
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <div class="room-modal__thumbnail"></div>
      <v-card-text>
        <div class="room-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form"
          >
            <div class="form__body">
              <v-text-field
                v-model="formData.name"
                outlined
                label="Nom de la pièce (Salon)"
                type="text"
                persistent-placeholder
                dense
                placeholder="Nom de la pièce (Salon)"
                :rules="[validations.required]"
              ></v-text-field>

              <v-row>
                <v-col cols="5">
                  <v-radio-group v-model="typeForm">
                    <v-radio label="Pièce droite" value="standart"></v-radio>
                    <v-radio label="Pièce irrégulière" value="specific"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="4">
                  <div class="image-wrapper">
                    <img :src="isStandart ? '/images/standart-sq.png' : '/images/specific-sq.png'" />
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="isStandart">
                <v-col cols="4">
                  <v-text-field
                    v-model.number="formData.length"
                    outlined
                    label="Longueur (m)"
                    type="text"
                    persistent-placeholder
                    dense
                    placeholder="Longueur"
                    :rules="[isStandart ? validations.required : '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.number="formData.width"
                    outlined
                    label="Largeur (m)"
                    type="text"
                    persistent-placeholder
                    dense
                    placeholder="Largeur"
                    :rules="[isStandart ? validations.required : '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.number="formData.height"
                    outlined
                    label="Hauteur (m)"
                    type="text"
                    persistent-placeholder
                    dense
                    placeholder="Hauteur"
                    :rules="[isStandart ? validations.required : '']"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12">
                  <v-text-field
                    v-model.number="formData.custom_square"
                    outlined
                    label="Surface (m2)"
                    type="text"
                    persistent-placeholder
                    dense
                    placeholder="Surface"
                    :rules="[!isStandart ? validations.required : '']"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                  :loading="loading"
                >
                  {{ mode === "new" ? "AJOUTER CETTE PIECE" : "Valider" }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
import { RoomService } from "@/services/room.service.js";

export default {
  name: "RoomModal",
  props: {
    show: {
      type: Boolean,
    },
    model: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      formData: null,
      valid: true,
      typeForm: "standart",
    };
  },
  computed: {
    validations() {
      return validations;
    },
    isStandart() {
      return this.typeForm === "standart";
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.form.resetValidation();
          });
        }
      },
    },
    model: {
      immediate: true,
      handler(newVal) {
        this.formData = Object.assign({}, newVal);
        newVal.custom_square ?  this.typeForm = "specific" : this.typeForm = "standart";
      },
    },
  },
  methods: {
    addNewRoom() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      const formData = this.isStandart
        ? { ...this.formData, custom_square: null }
        : { ...this.formData, length: null, width: null, height: null };

      RoomService.createQuotaRoom(formData)
        .then(({ data }) => {
          this.$emit("saveRoom", data);
          let redirectTo = {
            name: "RoomId",
            params: { roomId: data.id },
            query: {
              project_id: this.$route.query.project_id,
              quota_id: this.$route.query.quota_id,
            },
          };
          this.$route.params.roomId === "new"
            ? this.$router.replace(redirectTo)
            : this.$router.push(redirectTo);
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loading = false));
    },
    editRoom() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      const formData = this.isStandart
        ? { ...this.formData, custom_square: null }
        : { ...this.formData, length: null, width: null, height: null };

      RoomService.updateQuotaRoom({
        roomId: this.$route.params.roomId,
        room: formData,
      })
        .then(({ data }) => this.$emit("saveRoom", data))
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loading = false));
    },
    submit() {
      this.mode === "new" ? this.addNewRoom() : this.editRoom();
    },
  },
};
</script>

<style src="./RoomModal.scss" lang="scss" scoped></style>